import "../styles/globals.css";
import type { AppProps } from "next/app";
import Head from "next/head";
import TagManager from "react-gtm-module";
import { useEffect } from "react";

const App = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      TagManager.initialize({ gtmId: "GTM-KW82SCQ" });
    }
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="mask-icon" href="/favicon.ico" color="#f87171" />
        <meta charSet="utf-8" />
      </Head>
      <Component {...pageProps} />
    </>
  );
};

export default App;
